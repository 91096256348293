"use client";
import NextTopLoader from "nextjs-toploader";

export function ProgressBarWrap({ children, darkMode }: { children: React.ReactNode; darkMode?: boolean }) {
	return (
		<>
			{children}
			<NextTopLoader showSpinner={false} color={darkMode ? "#fff" : "#000"} height={2} />
		</>
	);
}
