import { getRequestConfig } from "next-intl/server";
import * as Sentry from "@sentry/nextjs";
import { type Translations } from "./messages/de";
import { Text } from "@/components/ui/text";
import { Link } from "./components/ui/Link";
import { Price } from "./components/util/Price";
import type { RichTranslationValues } from "next-intl";

// eslint-disable-next-line import/no-default-export
export default getRequestConfig(async ({ locale }) => {
	// this should never happen because the request is redirected in middleware.ts
	if (locale !== "de" && locale !== "en") {
		Sentry.captureMessage(`Invalid locale in getRequestConfig: ${locale}`);
		locale = "de";
	}

	return {
		messages: ((await import(`./messages/${locale}`)) as { translations: Translations }).translations,
		defaultTranslationValues,
	};
});

export const defaultTranslationValues: RichTranslationValues = {
	strong: (chunks) => (
		<Text size={null} weight="semibold" asChild>
			<strong>{chunks}</strong>
		</Text>
	),
	b: (chunks) => (
		<Text size={null} weight="semibold">
			{chunks}
		</Text>
	),
	stronger: (chunks) => (
		<Text size={null} weight="bold">
			{chunks}
		</Text>
	),
	currency: (chunks) => <Price price={chunks as number} />,
	mailto: (chunks) => (
		<Link underlined href={`mailto:${chunks as string}`}>
			{chunks}
		</Link>
	),
};
