"use client";
import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { graphql } from "@/gql-generated";

const query = graphql(`
	mutation Heartbeat {
		heartbeat(input: {}) {
			clientMutationId
		}
	}
`);

/**
 * Sends a heartbeat every minute to keep the session alive and maintain the state of the cart or queue position.
 */
export const Heartbeat = () => {
	const [heartbeat] = useMutation(query);

	useEffect(() => {
		const interval = setInterval(async () => {
			await heartbeat();
		}, 60000);

		return () => clearInterval(interval);
	}, [heartbeat]);

	return null;
};
