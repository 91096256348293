"use client";

import { pick } from "lodash";
import { NextIntlClientProvider, type AbstractIntlMessages } from "next-intl";
import { defaultTranslationValues } from "@/i18n";

// see https://next-intl-docs.vercel.app/docs/usage/configuration#default-translation-values for more details

export function ClientTranslationProvider({
	messages,
	children,
	locale,
}: {
	messages: AbstractIntlMessages;
	children: React.ReactNode;
	locale: string;
}) {
	return (
		<NextIntlClientProvider
			timeZone="Europe/Berlin"
			locale={locale}
			messages={pick(
				messages,
				"OrderList",
				"BandshopPassword",
				"Login",
				"FooterNewsletter",
				"ClientComponents",
				"AddToCart",
				"Header",
				"Navigation",
				"NotFound",
				"Error",
				"Event",
				"TicketLocationText",
				"Checkout",
				"CookieBanner",
				"LoginCode",
				"Account",
				"TicketList",
				"OrderList"
			)}
			defaultTranslationValues={defaultTranslationValues}
		>
			{children}
		</NextIntlClientProvider>
	);
}
