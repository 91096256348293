import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/node_modules/.pnpm/next-intl@3.4.2_next@14.2.0-canary.55_react@18.2.0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/.pnpm/next@14.2.0-canary.55_@babel+core@7.24.4_@playwright+test@1.43.1_react-dom@18.2.0_react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Instrument_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"fallback\",\"variable\":\"--font-instrument-sans\"}],\"variableName\":\"instrumentSans\"}");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/.pnpm/next@14.2.0-canary.55_@babel+core@7.24.4_@playwright+test@1.43.1_react-dom@18.2.0_react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/lib/fonts.ts\",\"import\":\"Instrument_Serif\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"400\",\"variable\":\"--font-instrument-serif\"}],\"variableName\":\"instrumentSerif\"}");
;
import(/* webpackMode: "eager", webpackExports: ["CookieBanner"] */ "/opt/buildhome/repo/src/components/content/CookieBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientTranslationProvider"] */ "/opt/buildhome/repo/src/components/contexts/ClientTranslationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShopDataProvider"] */ "/opt/buildhome/repo/src/components/contexts/shopData.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProgressBarWrap"] */ "/opt/buildhome/repo/src/components/layout/ProgressBarWrap.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Link"] */ "/opt/buildhome/repo/src/components/ui/Link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Heartbeat"] */ "/opt/buildhome/repo/src/components/util/Heartbeat.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/src/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ClientApiProvider"] */ "/opt/buildhome/repo/src/lib/api/client.tsx");
