"use client";

import { type getBandshop } from "@/lib/shop";
import { type getShopMeta } from "@/lib/shop-meta";
import { createContext, useContext } from "react";

type ShopData = Awaited<ReturnType<typeof getBandshop>> & { meta: Awaited<ReturnType<typeof getShopMeta>> };

// Create a new Context to provide data from shop query in client components
type ProviderProps = {
	children: React.ReactNode;
	shopData: ShopData | undefined;
};
const ShopDataContext = createContext<ShopData | undefined>(undefined);
export function ShopDataProvider({ children, shopData }: ProviderProps) {
	return <ShopDataContext.Provider value={shopData}>{children}</ShopDataContext.Provider>;
}

//
// Creating a custom hook so it's not necessary to import the context everywhere. The hook is enough
//

/**
 * Returns an object with shop data (slug, domain, meta)
 * @returns Object of shop data incl. meta data
 */
export function useShopData() {
	return useContext(ShopDataContext);
}
