"use client";

import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client/core";
import { endpoint, persistedQueriesLink } from "./shared";
import { ApolloProvider } from "@apollo/client/react";

export const ClientApiProvider = ({
	children,
	shopSlug,
	locale,
}: {
	children: React.ReactNode;
	shopSlug: string;
	locale: string;
}) => {
	const client = new ApolloClient({
		connectToDevTools: true,
		assumeImmutableResults: true,
		cache: new InMemoryCache(),
		ssrMode: false,
		link: persistedQueriesLink.concat(
			new HttpLink({
				uri: endpoint(shopSlug, locale),
			})
		),
	});
	return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
