"use client";
import { usePathname, useSearchParams } from "next/navigation";
import Script from "next/script";
import { useEffect } from "react";

// eslint-disable-next-line @cspell/spellchecker
const GTM_ID = "G-Q1M9T7TTKY";

export const Gtag = () => {
	const pathname = usePathname();
	const searchParams = useSearchParams();

	// notify Google Analytics of page view on page change
	useEffect(() => {
		const url = pathname + searchParams.toString();
		window.gtag("config", GTM_ID, {
			page_path: url,
		});
	}, [pathname, searchParams]);

	return (
		<>
			<Script src={`https://www.googletagmanager.com/gtag/js?id=${GTM_ID}`} strategy="afterInteractive" />
			<Script id="google-analytics" strategy="afterInteractive">
				{`
					window.dataLayer = window.dataLayer || [];
					function gtag(){window.dataLayer.push(arguments);}
					gtag('js', new Date());

					gtag('config', '${GTM_ID}');
        `}
			</Script>
		</>
	);
};
