"use client";

import { useShopData } from "@/components/contexts/shopData";
import { useParams, usePathname } from "next/navigation";
import { compact } from "lodash";

export function useLink() {
	const params = useParams();
	const pathname = usePathname();
	const shopData = useShopData();

	// generates a link relative to current bandshop and locale
	function getDynamicLink({
		href,
		replace,
	}: {
		href: string;
		replace?: { shopSlug?: string | false; locale?: string | false };
	}) {
		const currentBandshop = params.shopSlug as string;
		const currentLocale = params.locale as string;

		// Regex is true, if href path starts with "locale/" ends with "/locale" or includes "/locale/"
		const localeRegexp = new RegExp(`(^${currentLocale}\/)|(\/${currentLocale}$)|\/${currentLocale}\/`, "g");
		// new locale if given, no locale if none was in current url, current locale if one is given in current url
		let newLocale = replace?.locale ? replace.locale : localeRegexp.test(pathname) ? currentLocale : undefined;
		if (newLocale === shopData?.defaultLocale) newLocale = undefined;

		// Regex is true, if href path starts with "bandshop/" ends with "/bandshop" or includes "/bandshop/"
		const bandshopRegexp = new RegExp(
			`(^${currentBandshop}\/)|(\/${currentBandshop}$)|(\/${currentBandshop}\/)`,
			"g"
		);
		// new slug is given -> new slug (empty if "krasserstoff"). no slug if none was in current url, current slug if one is given in current url
		const newBandShop = replace?.shopSlug
			? replace.shopSlug === "krasserstoff"
				? undefined
				: replace.shopSlug
			: bandshopRegexp.test(pathname)
				? currentBandshop
				: undefined;

		let path = pathname;
		path = path.replace(localeRegexp, "/");
		path = path.replace(bandshopRegexp, "");

		let newHref = href;
		if (newHref.startsWith("/")) {
			// remove "/" to prevent "de/casper//products/..." after join below
			newHref = newHref.replace("/", "");
		} else if (newHref.startsWith("#")) {
			// if href starts with #, we just want to jump to the html element on the same page with the id, e.g. #header. So we want to stay on the current path. Still changes in the locale are possible
			newHref = path + newHref;
		} else if (newHref === "") {
			newHref = path;
		} else {
			return href;
		}

		newHref = `/${compact([newLocale, newBandShop, newHref]).join("/")}`;
		return newHref;
	}

	function getFullLink({
		href,
		replace,
	}: {
		href: string;
		replace?: { shopSlug?: string | false; locale?: string | false };
	}) {
		return `${location.host}${getDynamicLink({ href, replace })}`;
	}

	return { getDynamicLink, getFullLink };
}
