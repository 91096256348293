"use client";
import { useTranslations } from "next-intl";
import { Gtag } from "@/components/util/Gtag";
import Cookies from "js-cookie";

import { Button } from "../ui/button";
import { useEffect, useState } from "react";
import { Link } from "../ui/Link";

export function CookieBanner() {
	const t = useTranslations("CookieBanner");
	const [showBanner, setShowBanner] = useState(false); // needed to avoid hydration errors

	useEffect(() => {
		setShowBanner(true);
	}, []);

	if (!showBanner || Cookies.get("TrackingAllowed") === "no") return null;

	if (Cookies.get("TrackingAllowed") === "yes")
		return (
			<>
				<Gtag />
			</>
		);

	function allowTracking() {
		Cookies.set("TrackingAllowed", "yes");
		setShowBanner(false);
	}

	function denyTracking() {
		Cookies.set("TrackingAllowed", "no");
		setShowBanner(false);
	}

	return (
		<section className="sticky bottom-0 bg-white p-2 shadow-2xl" aria-label="Cookie Banner">
			<div className="flex justify-center pb-2">
				<span className="max-w-screen-lg" id="cookie-banner-disclaimer">
					{t.rich("disclaimer", {
						termsLink: (chunks) => (
							<Link href="/datapolicy" target="_blank" underlined onClick={(e) => e.preventDefault}>
								{chunks}
							</Link>
						),
					})}
				</span>
			</div>
			<div className="flex justify-center gap-4">
				{/* eslint-disable-next-line jsx-a11y/tabindex-no-positive */}
				<Button tabIndex={1} onClick={denyTracking} aria-describedby="cookie-banner-disclaimer">
					{t("deny")}
				</Button>
				<Button tabIndex={0} onClick={allowTracking} aria-describedby="cookie-banner-disclaimer" autoFocus>
					{t("allow")}
				</Button>
			</div>
		</section>
	);
}
