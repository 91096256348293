"use client";

import NextLink from "next/link";
import { useParams, usePathname } from "next/navigation";
import { type ComponentProps } from "react";
import { buttonVariants } from "./button";
import { type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/cn";
import { useShopData } from "../contexts/shopData";
import { useLink } from "@/lib/link";

export type LinkProps = ComponentProps<typeof NextLink> &
	VariantProps<typeof buttonVariants> & {
		underlineOnHover?: boolean;
		underlined?: boolean;
		shopSlug?: string;
		track?: TrackData;
	};

/** 
This component handles links for KS bandshops with the current locale.
	- It uses the Next.js link component and can be used exactly the same.
	- If a locale is set in the current path, the locale will automatically be applied to the link.
	- If a band slug is set in the current path, the band slug will automatically be applied to the link.
	- If href is an object or an external URL, the rules above will be ignored.

	E.g. we want to link to `/product/123` if current location `/` the link will be `/product/123`
	- `/en` -> `/en/product/123`
	- `/deichkind-shop` -> `/deichkind-shop/product/123`
	- `/en/deichkind-shop` -> `/en/deichkind-shop/product/123`
	- `https://www.google.com/` -> `https://www.google.com/`


	Clicks can be tracked by passing a track object:
	@example
	<Link href={trackingUrl} track={{ action: `track_shipment`, category: "account" }}>
		Paket verfolgen
	</Link>
*/
export function Link({
	href,
	className,
	shopSlug,
	underlineOnHover,
	underlined,
	locale,
	variant,
	track,
	onClick,
	...props
}: LinkProps): JSX.Element {
	const params = useParams();
	const pathname = usePathname();
	const underlineEffects = cn("first:*:hover-underline-animation first:*:hover:hover-underline-animation-hover");
	const underlinedClasses = cn("underline-offset-[2.5px] underline");
	const shopData = useShopData();
	const { getDynamicLink } = useLink();

	const externalRegEx = /https?:\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/i;
	//may be extended with more uri schemes if needed
	const schemeRegEx = /^(tel:)|(mailto:)/;

	const fullProps = {
		...props,
		className: cn(
			variant && buttonVariants({ variant }),
			underlineOnHover && underlineEffects,
			underlined && underlinedClasses,
			className
		),
		// we override onClick to track the click and then call the original onClick
		onClick: track
			? (e: React.MouseEvent<HTMLAnchorElement>) => {
					trackClick(track);
					if (typeof onClick === "function") onClick(e);
				}
			: onClick,
	};

	if (
		typeof href === "object" ||
		externalRegEx.test(href) ||
		!pathname ||
		// if URL object or external URL. Disabled ts because is says, that params always exists, although it can be null
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
		!params ||
		schemeRegEx.test(href) ||
		href.startsWith("#")
	) {
		return <NextLink href={href} {...fullProps} />;
	}
	const currentLocale = params.locale as string;

	// If current shop has it's own domain and we want to link to another shop -> redirect to krasserstoff.com
	if (shopSlug && shopData?.domain && shopSlug !== shopData.slug) {
		return (
			<NextLink
				href={`https://krasserstoff.com${shopSlug === "krasserstoff" ? "" : `/${shopSlug}`}/${currentLocale}${
					href.startsWith("/") ? "" : "/"
				}${href}`}
				{...fullProps}
			/>
		);
	}

	const newHref = getDynamicLink({ href, replace: { locale, shopSlug } });

	return (
		<>
			<NextLink href={newHref} {...fullProps} />
		</>
	);
}

type TrackData = { action: string; category?: string; label?: string };
function trackClick(data: TrackData) {
	if (typeof gtag === "function")
		gtag("event", data.action, {
			event_category: data.category,
			event_label: data.label,
		});
}
