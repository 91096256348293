import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";

export const persistedQueriesLink = createPersistedQueryLink({
	useGETForHashedQueries: true,
	sha256: (query: string) =>
		crypto.subtle.digest("SHA-256", new TextEncoder().encode(query)).then((hash) => {
			const hex = Array.from(new Uint8Array(hash))
				.map((b) => b.toString(16).padStart(2, "0"))
				.join("");
			return hex;
		}),
});

// on the client, we need to use the same origin as the current page to share cookies
// on the server, we can't use relative paths, so we need to use the full URL
const endpointRoot = typeof window !== "undefined" ? "" : `https://${process.env.NEXT_PUBLIC_API_HOST}`;

export const endpoint = (shopSlug: string, locale: string) =>
	`${endpointRoot}/api/v2/shops/${shopSlug}/graphql?locale=${locale}`;
